import { AxiosResponse } from 'axios';
import HttpClient from '../../utils/HttpClient';

export class RegisterService {
  static async resetPreferredMFA(
    username: string,
  ): Promise<AxiosResponse<boolean>> {
    return HttpClient.post(`/smsf-hub/register/reset-preferred-mfa`, {
      username: username,
    });
  }

  static async isValidRequest(
    username: string,
  ): Promise<AxiosResponse<boolean>> {
    return HttpClient.post(`/smsf-hub/register/is-valid-request`, {
      username: username,
    });
  }
}
