import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { TextField } from 'components/TextField/TextField';
import * as Yup from 'yup';
import {
  VERIFY_EMAIL,
  SIGN_UP,
  SMS_CODE_CONFIRM,
  TWO_FACTOR_SET_UP,
  AUTHENTICATOR_LOG_IN,
  EMAIL_RESET_PASSWORD,
  NEW_PASSWORD,
  CURRENT_PROCESS,
} from 'constants/registration';
import { AWSService } from 'services';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { LoginContainer } from './LoginContainer';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory, useLocation } from 'react-router-dom';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { useAuthContext } from 'contexts/authContext';
import useQuery from 'hooks/useQuery';
import classNames from 'classnames';

export const SignIn = () => {
  const query = useQuery();
  const [errorMsg, setError] = useState();
  const history = useHistory();
  const { setUser, user, setUserStatus, userStatus, setTempPwd } =
    useRegistration();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const redirect = query.get('redirect');
  const { setAuthState, setRedirectTo } = useAuthContext();

  const validate = Yup.object({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  useEffect(() => {
    redirect ? setRedirectTo(pathname) : setRedirectTo('/');
  }, []);

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <Formik
          initialValues={{
            email: user?.username ? user.username : '',
            password: '',
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            setError(null);
            setIsLoading(true);
            setTempPwd(values.password);
            try {
              const user = await AWSService.signIn(
                values.email,
                values.password,
              );
              user.pwd = values.password;
              setUser(user);
              setAuthState(CURRENT_PROCESS.SIGNIN);
              if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                history.push(NEW_PASSWORD.PATH);
              } else if (user.preferredMFA === 'NOMFA') {
                setAuthState(CURRENT_PROCESS.REGISTRATION);
                history.push(TWO_FACTOR_SET_UP.PATH);
              } else {
                setUserStatus({
                  ...userStatus,
                  isUserFirstSetupMfa: false,
                });

                if (user.challengeName === 'SMS_MFA') {
                  history.push(SMS_CODE_CONFIRM.PATH);
                } else {
                  history.push(AUTHENTICATOR_LOG_IN.PATH);
                }
              }
            } catch (e) {
              if (e.code === 'UserNotConfirmedException') {
                setUser({ username: values.email, pwd: values.password });
                setUserStatus((prevUserState) => ({
                  ...prevUserState,
                  isUserConfirmEmailLater: true,
                }));
                setAuthState(CURRENT_PROCESS.REGISTRATION);
                history.push(VERIFY_EMAIL.PATH);
              } else if (
                e.code === 'NotAuthorizedException' ||
                e.code === 'UserLambdaValidationException'
              ) {
                //do not use mapCustomError here as the same code from AWS use for different senario
                setError(
                  'Invalid email address or password. Too many failed login attempts will lock your account. When your account is locked, an email will be sent to your valid email address.',
                );
              } else {
                setError('Something went wrong please try after sometime.');
              }
            }
            setIsLoading(false);
          }}
        >
          {(formik, setFieldValue) => (
            <LoginContainer>
              <div>
                <Form>
                  <h1>Log in</h1>
                  <div className="mb-6">
                    <span>Need a Wrkr account? </span>
                    <a
                      className="link"
                      onClick={() => history.push(SIGN_UP.PATH)}
                    >
                      Create account
                    </a>
                  </div>
                  <div className="form-group">
                    <TextField
                      label="Email"
                      name="email"
                      type="text"
                      aria-label="email"
                    />
                  </div>
                  <div className="form-group">
                    <PasswordField
                      label="Password"
                      name="password"
                      className="eye-login"
                      role="password"
                      aria-label="password"
                    />
                  </div>
                  {errorMsg && <p className="error-message">{errorMsg}</p>}

                  <button
                    className="button button--primary button--lg button--full-width mb-4"
                    type="submit"
                  >
                    Log in
                  </button>
                  <br />
                  <br />
                  <div>
                    {/* <span>Forget your password? </span> */}
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(EMAIL_RESET_PASSWORD.PATH)}
                    >
                      Forgot password?
                    </a>
                  </div>
                </Form>
              </div>
            </LoginContainer>
          )}
        </Formik>
      </div>
    </div>
  );
};
