export const startPendo = (authUser, participant) => {
  const visitorId = authUser?.attributes['custom:guid'] ?? 'VISITOR-UNIQUE-ID';
  const accountId = participant?.guid ?? 'ACCOUNT-UNIQUE-ID';
  console.log(
    `Pendo agent started ... for user ${visitorId} and account : ${accountId} `,
  );

  const initializeData = {
    visitor: {
      id: visitorId,
      environment: process.env.REACT_APP_ENVIRONMENT,
    },

    account: {
      id: accountId,
    },
  };

  window.pendo.initialize(initializeData);
};
