import { useState } from 'react';
import { Formik, Form } from 'formik';
import { TextField } from 'components/TextField/TextField';
import * as Yup from 'yup';

import { SIGN_IN, RESET_PASSWORD } from 'constants/registration';
import { LoginContainer } from './LoginContainer';
import { AWSService } from 'services';
import { mapCustomError } from 'utils/mapServiceCallErrors';
import { useRegistration } from 'contexts/registrationContext';
import { useHistory } from 'react-router-dom';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { getPasswordRequirementError } from 'utils/customValidation';
import PasswordRequirements from 'components/PasswordRequirements/PasswordRequirements';
import useNavigationModal from 'hooks/useNavigationModal';
import { defaultBackButtonModal } from 'constants/navigationModals';
import { RegisterService } from 'services/RegisterService/RegisterService';

export const EmailResetPassword = () => {
  useNavigationModal(defaultBackButtonModal);

  // const query = useQuery();
  const [errorMsg, setError] = useState();
  const history = useHistory();
  const { setUserStatus, userStatus, setTempPwd } = useRegistration();
  const [isLoading, setIsLoading] = useState(false);
  const [requirements, setRequirements] = useState();
  const [clickContinue, setClickContinue] = useState(false);

  const validate = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.mixed().test('is-valid', '  ', function async(value) {
      return getPasswordRequirementError(value, setRequirements);
    }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      .required('Confirm new password'),
  });

  const isValidRequest = async (username) => {
    const { data } = await RegisterService.isValidRequest(username);
    return data;
  };

  return (
    <div className="container">
      <div className="registration__container">
        <SpinnerModal isOpen={isLoading} />
        <Formik
          initialValues={{
            email: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            setError(null);
            setIsLoading(true);
            try {
              if (await isValidRequest(values.email)) {
                const data = await AWSService.sendForgotPasswordCode(
                  values.email,
                );
                setUserStatus({
                  ...userStatus,
                  maskedEmail: data.CodeDeliveryDetails.Destination,
                  username: values.email,
                });
                setTempPwd(values.password);
                history.push(RESET_PASSWORD.PATH);
              } else {
                setError(
                  'We can’t proceed with your password reset request. Please check your email inbox for further information.',
                );
              }
            } catch (e) {
              console.log(e);
              setError(mapCustomError(e));
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {({ setFieldValue }) => (
            <>
              <LoginContainer>
                <div>
                  <Form>
                    <h1>Reset Your Password</h1>

                    <div className="form-group">
                      <TextField label="Email" name="email" type="email" />
                    </div>

                    <div className="form-group">
                      <PasswordField
                        label="New password"
                        name="password"
                        role="password"
                        className="eye-login"
                        aria-label="password"
                        maxLength="256"
                      />
                      <PasswordRequirements
                        requirements={requirements}
                        clickContinue={clickContinue}
                      />
                    </div>
                    <div className="form-group">
                      <PasswordField
                        label="Confirm password"
                        name="confirmPassword"
                        className="eye-login"
                        maxLength="256"
                      />
                    </div>
                    {errorMsg && <p className="error-message">{errorMsg}</p>}
                    <button
                      className="button button--primary button--lg button--full-width mb-4"
                      type="submit"
                      onClick={() => setClickContinue(true)}
                    >
                      Reset password
                    </button>

                    <br />
                    <br />
                    <div>
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => history.push(SIGN_IN.PATH)}
                      >
                        Return to login
                      </a>
                    </div>
                  </Form>
                </div>
              </LoginContainer>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};
