import {
  AWSUser,
  SignUpRequest,
  MFATypes,
  ChallengeName,
} from './types/AWSService.types';
import { Auth } from 'aws-amplify';
import {
  CHANNEL_ID,
  USER_PARTICIPANT_ROLE,
  USER_ROLE,
} from '../../constants/registration';
import { v4 as uuidv4 } from 'uuid';
import { RegisterService } from '../RegisterService/RegisterService';

export class AWSService {
  static async signUp(signUpRequest: SignUpRequest) {
    return await Auth.signUp({
      username: signUpRequest.email,
      password: signUpRequest.password,
      attributes: {
        email: signUpRequest.email,
        name: signUpRequest.firstName + ' ' + signUpRequest.lastName,
        phone_number: '+61' + signUpRequest?.mobileNumber?.replace(/^0+/, ''),
        'custom:guid': `USR-${uuidv4()}`,
        'custom:role': USER_ROLE,
        'custom:channel': CHANNEL_ID,
        'custom:qrcode': 'not-set',
        'custom:locked_untill': '0',
        'custom:number_of_attempts': '0',
      },
    });
  }

  static async signIn(username: string, passwd: string) {
    return await Auth.signIn(username, passwd);
  }

  static async verifyEmail(username: string, code: string) {
    return await Auth.confirmSignUp(username, code);
  }

  static async resendSignUp(username: string) {
    return await Auth.resendSignUp(username);
  }

  static async setPreferredMFA(mfaType: MFATypes) {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.setPreferredMFA(user, mfaType);
  }

  static async confirmSignIn(
    user: AWSUser,
    otp: string,
    mfaType: ChallengeName,
  ) {
    return await Auth.confirmSignIn(user, otp, mfaType);
  }

  static async verifyTotpToken(mfaType: MFATypes) {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.verifyTotpToken(user, mfaType);
  }

  static async setupTOTP() {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.setupTOTP(user);
  }

  static async currentAuthenticatedUser(params?: any) {
    return await Auth.currentAuthenticatedUser(params);
  }

  static async updateUserRoleAttributes() {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, {
      'custom:role': USER_PARTICIPANT_ROLE,
    });
  }

  static async updateUserQrCodeAttributes(newQrCode: string) {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, {
      'custom:qrcode': newQrCode,
    });
  }

  static async getCurrentUserRole(): Promise<String> {
    const user = await Auth.currentAuthenticatedUser();
    return user?.attributes?.['custom:role'];
  }

  static configure(config?: any) {
    return Auth.configure(config);
  }

  static async sendForgotPasswordCode(username: string) {
    return await Auth.forgotPassword(username);
  }

  static async submitForgotPasswordCode(
    username: string,
    code: string,
    new_password: string,
  ) {
    await Auth.forgotPasswordSubmit(username, code, new_password);
  }

  static async completeNewPassword(
    user: AWSUser,
    newPassword: string,
    requiredAttributes?: any,
  ) {
    return await Auth.completeNewPassword(
      user,
      newPassword,
      requiredAttributes,
    );
  }

  static async isHubUserCreated(): Promise<boolean> {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes['custom:role'] === USER_PARTICIPANT_ROLE;
  }
}
