import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ReactComponent as InfoCircle } from 'bootstrap-icons/icons/info-circle.svg';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { ErrorMessage, Form, Formik } from 'formik';
import pick from 'lodash/pick';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg';
import debounce from 'lodash.debounce';
import HttpClient from 'utils/HttpClient';
import { convertToIsoDateFormat } from 'utils/format';
import { calculateTotal } from 'utils/calculateTotalAmount';
import { mapError } from 'utils/mapServiceCallErrors';
import { testAmountHasNoMoreThanTwoDecimalPlaces } from 'utils/validateRolloverAmount';
import {
  DEATH_BENEFIT_RECIPIENT_AGE_CATEGORY_CODE,
  DEATH_BENEFIT_TAX_CODE,
  ESA_ALIASES,
} from 'utils/configs';
import { AsyncFormSelect, FormSelect } from 'components/FormSelect/FormSelect';
import Checkbox from 'components/Checkbox/Checkbox';
import {
  FormRadioButton,
  FormRadioGroup,
} from 'components/FormRadio/FormRadio';
import DayInput from 'components/DayInput/DayInput';
import ValidationError from 'components/ValidationError/ValidationError';
import Alert from 'components/Alert/Alert';
import MemberDetails from 'components/MemberDetails/MemberDetails';
import Spinner from 'components/Spinner/Spinner';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';
import FormGroup from 'components/FormGroup/FormGroup';
import {
  VALID_ACCOUNT_NAME,
  VALID_ACCOUNT_NUMBER,
  VALID_ACOUNT_NAME_LENGTH,
  VALID_AMOUNT_REGEX,
  VALID_BSB_NUMBER,
} from 'constants/validation';
import { isUSIExpired, isUSISupportV3 } from 'utils/customValidation';
import DisabledFields from 'components/DisabledFields/DisabledFields';
import { useFundContext } from 'contexts/fundContext';
import { SMSF_STATUS } from '../../constants/smsf';

Yup.addMethod(Yup.mixed, 'isUSIExpired', isUSIExpired);
Yup.addMethod(Yup.mixed, 'isUSISupportV3', isUSISupportV3);

const RTRDataSchema = Yup.object().shape({
  memberGuid: Yup.string().required('Member is required'),

  proofOfIdentityVerificationConfirmed: Yup.boolean().oneOf(
    [true],
    'Confirmation is required',
  ),

  receivingFund: Yup.object().shape({
    fundType: Yup.string()
      .oneOf(['APRA', 'SMSF'])
      .required('Receiving fund is required'),
    fundEsa: Yup.string().when('fundType', {
      is: 'SMSF',
      then: Yup.string()
        .oneOf(ESA_ALIASES, 'ESA is required')
        .required('ESA is required'),
      otherwise: Yup.string(),
    }),
    bankBranchNumber: Yup.string().when('fundType', {
      is: 'SMSF',
      then: Yup.string()
        .matches(VALID_BSB_NUMBER, 'Please enter a valid 6-digit BSB number')
        .required('BSB is required'),
      otherwise: Yup.string(),
    }),
    effectiveEndDate: Yup.mixed().isUSIExpired(),
    certificationDetails: Yup.mixed().isUSISupportV3(),
    accountNumber: Yup.string().when('fundType', {
      is: 'SMSF',
      then: Yup.string()
        .matches(VALID_ACCOUNT_NUMBER, 'Please enter a valid account number')
        .required('Account number is required'),
      otherwise: Yup.string(),
    }),
    accountName: Yup.string().when('fundType', {
      is: 'SMSF',
      then: Yup.string()
        .matches(
          VALID_ACCOUNT_NAME,
          "Please enter an account name which contain only these characters ([0-9a-zA-Z .(){}'|-/#*&])*",
        )
        .required('Account name is required')
        .max(VALID_ACOUNT_NAME_LENGTH),
      otherwise: Yup.string(),
    }),
  }),

  eligibleServicePeriodStartDate: Yup.string().required(
    'Eligible service period start date is required',
  ),

  superannuationRollover: Yup.object().shape({
    transferWholeBalanceIndicator: Yup.string()
      .oneOf(['true', 'false'])
      .required('Amount to transfer is required'),
    benefitPreservedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Benefit Preserved Amount')
      .required('Benefit Preserved Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    benefitUnrestrictedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Benefit Unrestricted Amount')
      .required('Benefit Unrestricted Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    benefitRestrictedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Benefit Restricted Amount')
      .required('Benefit Restricted Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    insuredDeathCoverAmount: Yup.string().when('insuranceSelected', {
      is: true,
      then: Yup.string()
        .matches(VALID_AMOUNT_REGEX, 'Invalid Death Cover Insurance Amount')
        .required('Death Cover Insurance Amount is required')
        .test(testAmountHasNoMoreThanTwoDecimalPlaces),
      otherwise: Yup.string(),
    }),
    insuredTotalPermanentDisablementCoverAmount: Yup.string().when(
      'insuranceSelected',
      {
        is: true,
        then: Yup.string()
          .matches(
            VALID_AMOUNT_REGEX,
            'Invalid Permanent Disable Insurance Amount',
          )
          .required('Permanent Disable Insurance Amount is required')
          .test(testAmountHasNoMoreThanTwoDecimalPlaces),
        otherwise: Yup.string(),
      },
    ),
    insuredIncomeProtectionMonthlyCoverAmount: Yup.string().when(
      'insuranceSelected',
      {
        is: true,
        then: Yup.string()
          .matches(
            VALID_AMOUNT_REGEX,
            'Invalid Income Protection Monthly Cover Amount',
          )
          .required('Income Protection Monthly Cover Amount is required')
          .test(testAmountHasNoMoreThanTwoDecimalPlaces),
        otherwise: Yup.string(),
      },
    ),
    kiwiSaverPreservedAmount: Yup.string().when('kiwiSaverSelected', {
      is: true,
      then: Yup.string()
        .matches(VALID_AMOUNT_REGEX, 'Invalid KiwiSaver Preserved Amount')
        .required('KiwiSaver Preserved Amount is required')
        .test(testAmountHasNoMoreThanTwoDecimalPlaces),
      otherwise: Yup.string(),
    }),
    kiwiSaverTaxFreeAmount: Yup.string().when('kiwiSaverSelected', {
      is: true,
      then: Yup.string()
        .matches(VALID_AMOUNT_REGEX, 'Invalid KiwiSaver Tax Free Amount')
        .required('KiwiSaver Tax Free Amount is required')
        .test(testAmountHasNoMoreThanTwoDecimalPlaces),
      otherwise: Yup.string(),
    }),
    deathBenefitRecipientAgeCategoryCode: Yup.string().when(
      'deathBenefitSelected',
      {
        is: true,
        then: Yup.string()
          .oneOf(
            DEATH_BENEFIT_RECIPIENT_AGE_CATEGORY_CODE.map((it) => it.value),
            'Death benefit recipient category is required',
          )
          .required('Death benefit recipient category is required'),
        otherwise: Yup.string(),
      },
    ),
    deathBenefitTaxCode: Yup.string().when('deathBenefitSelected', {
      is: true,
      then: Yup.string()
        .oneOf(
          DEATH_BENEFIT_TAX_CODE.map((it) => it.value),
          'Death benefit tax treatment type is required',
        )
        .required('Death benefit tax treatment type is required'),
      otherwise: Yup.string(),
    }),
  }),

  incomeTax: Yup.object().shape({
    taxableTaxedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Taxed Amount')
      .required('Taxed Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    taxableUntaxedAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Untaxed Amount')
      .required('Untaxed Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
    taxFreeAmount: Yup.string()
      .matches(VALID_AMOUNT_REGEX, 'Invalid Tax Free Amount')
      .required('Tax Free Amount is required')
      .test(testAmountHasNoMoreThanTwoDecimalPlaces),
  }),

  totalAmount: Yup.string().test(
    'is-greater-than-zero',
    (value, { createError }) => {
      let message;
      if (value?.length > 0) {
        if (
          !VALID_AMOUNT_REGEX.test(value) ||
          Number.isNaN(parseFloat(value))
        ) {
          message = 'Invalid Total amount';
        } else if (!parseFloat(value) > 0) {
          message =
            'The total amount (ie. the sum of benefits) must be greater than $0';
        }
      }
      return message
        ? createError({
            message,
          })
        : true;
    },
  ),
});

const MemberSelectOption = ({ member }) => {
  const { lastName, givenName, taxFileNumber } = member;
  return (
    <div>
      {givenName} {lastName}{' '}
      <span className="ml-2 is-light">(TFN: {taxFileNumber})</span>
    </div>
  );
};

const RollOutRequest = () => {
  document.title = 'New Roll Out Request';
  const history = useHistory();
  const { guid, messageTransactionId } = useParams();
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [expandMoreInfo, setExpandMoreInfo] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { state } = useLocation();
  const [isLoaded, setIsLoaded] = useState(true);
  const [submissionError, setSubmissionError] = useState(null);
  const fund = useFundContext();
  const smsfStatus = fund?.smsfStatus;

  const initialValues = {
    abn: '',
    proofOfIdentityVerificationConfirmed: false,
    memberGuid: !!selectedMember ? selectedMember.memberGuid : '',
    totalAmount: '',
    receivingFund: {
      fundType: !!state?.receivingFund?.fundType
        ? state.receivingFund.fundType
        : '',
      fundAbn: !!state?.receivingFund?.fundAbn
        ? state.receivingFund.fundAbn
        : '',
      fundEsa: !!state?.receivingFund?.fundEsa
        ? state.receivingFund.fundEsa
        : '',
      fundName: !!state?.receivingFund?.fundName
        ? state.receivingFund.fundName
        : '',
      fundUsi: !!state?.receivingFund?.fundUsi
        ? state.receivingFund.fundUsi
        : '',
      bankBranchNumber: !!state?.receivingFund?.financialInstitutionAccount
        ?.bankBranchNumber
        ? state.receivingFund.financialInstitutionAccount.bankBranchNumber
        : '',
      accountNumber: !!state?.receivingFund?.financialInstitutionAccount
        ?.accountNumber
        ? state.receivingFund.financialInstitutionAccount.accountNumber
        : '',
      accountName: !!state?.receivingFund?.financialInstitutionAccount
        ?.accountName
        ? state.receivingFund.financialInstitutionAccount.accountName
        : '',
      effectiveEndDate: !!state?.receivingFund?.effectiveEndDate
        ? state.receivingFund.effectiveEndDate
        : null,
      certificationDetails: !!state?.receivingFund?.certificationDetails
        ? state.receivingFund.certificationDetails
        : null,
    },
    memberIdInReceivingFund: !!state?.member?.memberIdInReceivingFund
      ? state.member.memberIdInReceivingFund
      : '',
    eligibleServicePeriodStartDate: '',
    incomeTax: {
      taxableTaxedAmount: '0',
      taxableUntaxedAmount: '0',
      taxFreeAmount: '0',
    },
    superannuationRollover: {
      deathBenefitSelected: false,
      insuranceSelected: false,
      kiwiSaverSelected: false,
      benefitPreservedAmount: '0',
      benefitUnrestrictedAmount: '0',
      benefitRestrictedAmount: '0',
      insuredDeathCoverAmount: '0',
      insuredTotalPermanentDisablementCoverAmount: '0',
      insuredIncomeProtectionMonthlyCoverAmount: '0',
      transferWholeBalanceIndicator: '',
      remainingBenefitIndicator: false,
      kiwiSaverPreservedAmount: '0',
      kiwiSaverTaxFreeAmount: '0',
      deathBenefitRecipientAgeCategoryCode: '',
      deathBenefitTaxCode: '',
    },
  };

  const getRTRReferenceData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await HttpClient.get('/smsf-hub/rollover/reference-data', {
        params: {
          smsfGuid: guid,
          taxFileNumber: !!state?.member?.tfn ? state.member.tfn : null,
          dateOfBirth: !!state?.member?.dateOfBirth
            ? state.member.dateOfBirth
            : null,
          firstName: !!state?.member?.givenName ? state.member.givenName : null,
          lastName: !!state?.member?.familyName
            ? state.member.familyName
            : null,
        },
      });
      const { data } = res;
      if (!!state?.member && data.smsfMembers.length === 1) {
        setSelectedMember(data.smsfMembers[0]);
      } else {
        setMembers(
          data.smsfMembers.map((member) => ({
            value: member.memberGuid,
            label: <MemberSelectOption member={member} />,
          })),
        );
      }
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      console.log(error);
    }
  }, [guid]);

  useEffect(() => {
    getRTRReferenceData();
  }, [getRTRReferenceData]);

  useEffect(() => {}, [selectedMember]);

  const searchByQuery = async (query) => {
    try {
      const { data } = await HttpClient.get(
        `/smsf-hub/superfund/search/search?query=${query}`,
      );

      if (data.fundProducts) {
        return data.fundProducts.map((product) => {
          if (product.fundType === 'SMSF') {
            return {
              value: {
                fundType: product.fundType,
                fundAbn: product.abn,
                fundName: product.fundName,
                productName: '',
                fundUsi: '',
                fundEsa: '',
                bankBranchNumber: '',
                accountNumber: '',
                accountName: '',
                effectiveEndDate: product.effectiveEndDate,
                certificationDetails: product.certificationDetails,
              },
              label: (
                <div>
                  {product.fundName}
                  <span className="ml-2 is-light">(ABN: {product.abn})</span>
                </div>
              ),
            };
          } else {
            return {
              value: {
                fundType: product.fundType,
                fundAbn: product.abn,
                fundName: product.fundName,
                fundUsi: product.usi,
                productName: product.productName,
                bankBranchNumber: product.bankBranchNumber,
                accountNumber: product.accountNumber,
                accountName: product.accountName,
                effectiveEndDate: product.effectiveEndDate,
                certificationDetails: product.certificationDetails,
              },
              label: (
                <div>
                  {product.productName}
                  <span className="ml-2 is-light">(USI: {product.usi} )</span>
                </div>
              ),
            };
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchFundByQuery = debounce((query, callback) => {
    searchByQuery(query).then((resp) => callback(resp));
  }, 3000);

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsProcessing(true);
    const {
      memberGuid,
      proofOfIdentityVerificationConfirmed,
      receivingFund,
      memberIdInReceivingFund,
      incomeTax,
      superannuationRollover,
      totalAmount,
      eligibleServicePeriodStartDate,
    } = values;

    try {
      const { data } = await HttpClient.post(
        '/smsf-hub/roll-out/rollover-transaction/rollover-transaction-request',
        {
          initiatorMessageTransactionId: messageTransactionId,
          memberDetails: {
            initiatorMemberDetails: !!state?.member
              ? pick(state.member, [
                  'familyName',
                  'givenName',
                  'otherGivenName',
                  'dateOfBirth',
                  'tfn',
                  'address',
                  'gender',
                ])
              : null,
            initiatorMemberContextId: state?.member?.memberContextId,
            memberGuid,
            proofOfIdentityVerificationConfirmed,
            superannuationFund: {
              memberIdInReceivingFund:
                receivingFund?.fundType === 'SMSF'
                  ? 'UNKNOWN'
                  : memberIdInReceivingFund,
              eligibleServicePeriodStartDate: eligibleServicePeriodStartDate
                ? convertToIsoDateFormat(eligibleServicePeriodStartDate)
                : null,
            },
            incomeTax,
            superannuationRollover: {
              ...superannuationRollover,
              insuredDeathCoverAmount: superannuationRollover?.insuranceSelected
                ? superannuationRollover.insuredDeathCoverAmount
                : null,
              insuredTotalPermanentDisablementCoverAmount:
                superannuationRollover?.insuranceSelected
                  ? superannuationRollover.insuredTotalPermanentDisablementCoverAmount
                  : null,
              insuredIncomeProtectionMonthlyCoverAmount:
                superannuationRollover?.insuranceSelected
                  ? superannuationRollover.insuredIncomeProtectionMonthlyCoverAmount
                  : null,
              kiwiSaverPreservedAmount:
                superannuationRollover?.kiwiSaverSelected
                  ? superannuationRollover.kiwiSaverPreservedAmount
                  : null,
              kiwiSaverTaxFreeAmount: superannuationRollover?.kiwiSaverSelected
                ? superannuationRollover.kiwiSaverTaxFreeAmount
                : null,
              deathBenefitRecipientAgeCategoryCode:
                superannuationRollover?.deathBenefitSelected
                  ? superannuationRollover.deathBenefitRecipientAgeCategoryCode
                  : null,
              deathBenefitTaxCode: superannuationRollover?.deathBenefitSelected
                ? superannuationRollover.deathBenefitTaxCode
                : null,
            },
          },
          receiverDetails: pick(receivingFund, [
            'fundAbn',
            'fundEsa',
            'fundName',
            'fundUsi',
          ]),
          senderDetails: {
            smsfGuid: guid,
          },
          payment: {
            financialInstitutionAccount: pick(receivingFund, [
              'bankBranchNumber',
              'accountNumber',
              'accountName',
            ]),
            paymentAmount: totalAmount,
          },
        },
      );
      setSubmitting(false);
      setIsProcessing(false);
      history.push(
        `/smsfs/${guid}/rollovers/transaction-details/${data.messageTransactionId}?action=ROLLOVER_TRANSACTION_REQUEST`,
      );
    } catch (error) {
      setSubmitting(false);
      setIsProcessing(false);
      setSubmissionError(mapError(error));
    }
  };

  return (
    <div className="NewRollover">
      <div className="banner">
        <Breadcrumbs />
        <h1>New Roll Out Request</h1>
      </div>
      <SpinnerModal
        isOpen={isProcessing}
        message="Please wait whilst we process your request"
      />
      {isLoaded ? (
        <div className="content">
          <div className="new-rollover-request-form">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={RTRDataSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                setFieldValue,
                isSubmitting,
                setFieldTouched,
                form,
              }) => (
                <Form id="new-member-form" className="form">
                  <DisabledFields disabled={smsfStatus !== SMSF_STATUS.active}>
                    <div
                      className="panel"
                      role="group"
                      aria-labelledby="member-details"
                    >
                      <h2 id="member-details">Member details</h2>
                      {!!state?.member && (
                        <Alert variation="info" title="Verify member details">
                          <p>
                            The member details below have been pre-filled using
                            the request received. If there are any issues with
                            the details, you must contact the transferring fund
                            or member directly.
                          </p>
                        </Alert>
                      )}
                      <MemberDetails
                        guid={guid}
                        member={state?.member}
                        members={members}
                      />
                      {selectedMember == null && members?.length > 0 && (
                        <FormGroup name="memberGuid" label="Select member">
                          {({ form, meta, name, value, ...props }) => (
                            <FormSelect
                              id="member"
                              options={members}
                              {...props}
                              name={name}
                              value={members.find(
                                (member) => member.value === value,
                              )}
                              onChange={(option) =>
                                form.setFieldValue(name, option.value)
                              }
                            />
                          )}
                        </FormGroup>
                      )}
                      {(selectedMember != null || members?.length > 0) && (
                        <>
                          <FormGroup
                            id="eligibleServicePeriodStartDate"
                            name="eligibleServicePeriodStartDate"
                            label={
                              <span className="d-inline-flex align-items-center">
                                <span>Eligible service period start date</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        The{' '}
                                        <strong>
                                          service period start date
                                        </strong>{' '}
                                        is usually either:{' '}
                                      </p>
                                      <ul className="list">
                                        <li>
                                          the first day of the first period of
                                          employment that the lump sum relates
                                          to if the member was employed when
                                          some or all of the lump sum accrued
                                        </li>
                                        <li>
                                          the earlier of the following if the
                                          member was not employed when some or
                                          all of the lump sum accrued
                                          <ul>
                                            <li>
                                              the date when the member joined
                                              your fund
                                            </li>
                                            <li>
                                              if the lump sum is attributable to
                                              an earlier lump sum previously
                                              rolled over, the first day of the
                                              service period of the earlier lump
                                              sum.
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                            placeholder="dd/mm/yyyy"
                            as={DayInput}
                            hasMaxYear={true}
                          />
                          <h3 id="member-details" className="mt-8">
                            Proof of identity
                          </h3>
                          <div className="panel">
                            <p>
                              <strong>
                                You must verify this member's identity in order
                                to process their rollover requests.
                              </strong>
                            </p>
                            <button
                              type="button"
                              onClick={() => setExpandMoreInfo(!expandMoreInfo)}
                              className="button button--link mb-4"
                            >
                              More info
                              {expandMoreInfo ? (
                                <CaretUpFill className="button--icon-after__icon" />
                              ) : (
                                <CaretDownFill className="button--icon-after__icon" />
                              )}
                            </button>
                            {expandMoreInfo && (
                              <div>
                                <h4>
                                  You must verify the following primary or
                                  secondary documents:-
                                </h4>
                                <p>
                                  <strong>EITHER</strong>
                                </p>

                                <div className="panel panel--narrow">
                                  <p>
                                    <strong>A certified copy of:</strong>
                                  </p>
                                  <ul className="list">
                                    <li>
                                      drivers licence issued under state or
                                      territory law
                                    </li>
                                    <li>
                                      passport issued by the Commonwealth that
                                      has not expired within the past two years
                                    </li>
                                  </ul>
                                </div>
                                <p>
                                  <strong>OR</strong>
                                </p>
                                <div className="panel panel--narrow">
                                  <p>
                                    <strong>
                                      Any ONE of the following non‑photographic
                                      identification documents:
                                    </strong>
                                  </p>
                                  <ul className="list">
                                    <li>
                                      birth certificate or birth extract issued
                                      by a State or Territory of Australia{' '}
                                    </li>
                                    <li>
                                      citizenship certificate issued by the
                                      Commonwealth
                                    </li>
                                    <li>
                                      {' '}
                                      pension card issued by Centrelink that
                                      entitles the person to financial benefits
                                    </li>
                                  </ul>
                                  <p>
                                    <strong>AND</strong>
                                  </p>
                                  <p>
                                    <strong>
                                      Any ONE of the following secondary
                                      identification documents:
                                    </strong>
                                  </p>
                                  <ul className="list">
                                    <li>
                                      notice issued by the Commonwealth, or
                                      State or Territory government within the
                                      past 12 months that records the provision
                                      of financial benefits and contains your
                                      name and residential address – for example
                                      – letter from Centrelink regarding a
                                      government assistance payment{' '}
                                    </li>
                                    <li>
                                      notice issued by the ATO within the past
                                      twelve months that contains your name and
                                      residential address – for example – notice
                                      of an ATO assessment
                                    </li>
                                    <li>
                                      notice issued by a local government body
                                      or utilities provider within the previous
                                      three months – for example – a rates
                                      notice from local government.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                            <p>
                              <em>
                                <strong>Note:</strong> The verification of the
                                member's identity is an internal check that must
                                be done by you. You do not need to send any
                                identity documents to Wrkr.
                              </em>
                            </p>
                            <hr />
                            <Checkbox
                              id="proofOfIdentityVerificationConfirmed"
                              checked={
                                values.proofOfIdentityVerificationConfirmed
                              }
                              setChecked={() =>
                                setFieldValue(
                                  'proofOfIdentityVerificationConfirmed',
                                  !values.proofOfIdentityVerificationConfirmed,
                                )
                              }
                              label="I have verified this member's identity and confirm that this member is the person to whom the super entitlements belong"
                            />
                            <ErrorMessage
                              component="div"
                              className="error"
                              name="proofOfIdentityVerificationConfirmed"
                            />
                          </div>
                        </>
                      )}
                    </div>

                    {selectedMember != null || members?.length > 0 ? (
                      <>
                        <div
                          className="panel"
                          role="group"
                          aria-labelledby="receiving-fund-details"
                          aria-describedby="receiving-fund-details-description"
                        >
                          <h2 id="receiving-fund-details">
                            Receiving fund details
                          </h2>
                          <p id="receiving-fund-details-description">
                            Enter the details of the fund that will be receiving
                            payment
                          </p>
                          {!!state?.receivingFund ? (
                            <Alert
                              variation="details"
                              title={`${values.receivingFund.fundName} (${values.receivingFund.fundType})`}
                            >
                              {!!values.receivingFund.fundUsi ? (
                                <p>USI: {values.receivingFund.fundUsi}</p>
                              ) : null}
                              <p>ABN: {values.receivingFund.fundAbn}</p>
                              {!!values?.receivingFund?.bankBranchNumber ? (
                                <p>
                                  BSB: {values?.receivingFund?.bankBranchNumber}
                                </p>
                              ) : null}
                              {!!values?.receivingFund?.accountNumber ? (
                                <p>
                                  Account no:{' '}
                                  {values?.receivingFund?.accountNumber}
                                </p>
                              ) : null}
                              {!!values?.receivingFund?.accountName ? (
                                <p>
                                  Account name:{' '}
                                  {values?.receivingFund?.accountName}
                                </p>
                              ) : null}
                            </Alert>
                          ) : (
                            <div className="form-group">
                              <label
                                htmlFor="receiving-fund"
                                className="d-inline-flex align-items-center"
                              >
                                <span>Receiving fund</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        The fund that will be receiving the
                                        rollover payment.
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </label>
                              <AsyncFormSelect
                                id="receiving-fund"
                                placeholder="Search by ABN, USI or fund name"
                                cacheOptions
                                noOptionsMessage={({ inputValue }) =>
                                  !inputValue
                                    ? 'Start typing...'
                                    : 'No results found'
                                }
                                loadingMessage={() => 'Loading...'}
                                loadOptions={searchFundByQuery}
                                value={values.receivingFund.value}
                                onChange={(selected) => {
                                  setFieldTouched('receivingFund.fundType');
                                  setFieldTouched(
                                    'receivingFund.effectiveEndDate',
                                  );
                                  setFieldTouched(
                                    'receivingFund.certificationDetails',
                                  );
                                  setFieldValue(
                                    'receivingFund',
                                    selected.value,
                                  );
                                }}
                              />
                              <ErrorMessage
                                component="div"
                                className="error"
                                name="receivingFund.fundType"
                              />
                              <ErrorMessage
                                component="div"
                                className="error"
                                name="receivingFund.effectiveEndDate"
                              />
                              <ErrorMessage
                                component="div"
                                className="error"
                                name="receivingFund.certificationDetails"
                              />
                              {values.receivingFund.fundType !== '' ? (
                                <div className="fund-option">
                                  <div>
                                    <div className="subtitle">
                                      {values.receivingFund.fundType}
                                    </div>
                                    <div className="fund-name">
                                      {values.receivingFund.fundName}
                                    </div>
                                    {!!values.receivingFund.productName ? (
                                      <div className="fund-no">
                                        <strong>Product name:</strong>{' '}
                                        {values.receivingFund.productName}
                                      </div>
                                    ) : null}
                                    {!!values.receivingFund.fundUsi ? (
                                      <div className="fund-no">
                                        <strong>USI:</strong>{' '}
                                        {values.receivingFund.fundUsi}
                                      </div>
                                    ) : null}
                                    <div className="fund-no">
                                      <strong>ABN:</strong>{' '}
                                      {values.receivingFund.fundAbn}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          )}

                          {values.receivingFund?.fundType === 'SMSF' && (
                            <>
                              <FormGroup
                                name="receivingFund.fundEsa"
                                label="ESA"
                                as="select"
                              >
                                <option>Please select...</option>
                                {ESA_ALIASES.map((alias) => (
                                  <option key={alias} value={alias}>
                                    {alias}
                                  </option>
                                ))}
                              </FormGroup>
                              <h3>Bank account</h3>
                              <FormGroup
                                name="receivingFund.bankBranchNumber"
                                label="BSB"
                              />
                              <FormGroup
                                name="receivingFund.accountNumber"
                                label="Account no."
                              />
                              <FormGroup
                                name="receivingFund.accountName"
                                label="Account name"
                              />
                            </>
                          )}

                          {values.receivingFund?.fundType === 'APRA' && (
                            <FormGroup
                              name="memberIdInReceivingFund"
                              label="Receiving Member ID"
                            />
                          )}
                        </div>

                        <div
                          className="panel"
                          role="group"
                          aria-labelledby="rollout-amount-details"
                        >
                          <h2 id="payment-details">Rollover details</h2>
                          <p>
                            <strong>
                              If you are unsure how to complete this form,
                              please contact your advisor.
                            </strong>
                          </p>

                          {!!state?.member?.requestedAmount && (
                            <Alert variation="details" title="Rollout details">
                              <p>
                                Requested amount: {state.member.requestedAmount}
                              </p>
                            </Alert>
                          )}

                          <h3>Amount to transfer</h3>
                          <FormRadioGroup
                            id="amount-to-transfer"
                            name="superannuationRollover.transferWholeBalanceIndicator"
                          >
                            <FormRadioButton
                              id="whole-balance"
                              name="superannuationRollover.transferWholeBalanceIndicator"
                              value="true"
                              label={
                                <span className="d-inline-flex align-items-top">
                                  <span>Full rollover</span>
                                  <Tippy
                                    arrow={true}
                                    placement="top"
                                    animation="shift-away"
                                    theme="light-border"
                                    content={
                                      <div className="tooltip-popup">
                                        <p>
                                          The entire balance of the member's
                                          contributions will be rolled over to
                                          the receiving fund.
                                        </p>
                                      </div>
                                    }
                                  >
                                    <span className="tooltip-icon">
                                      <InfoCircle />
                                    </span>
                                  </Tippy>
                                </span>
                              }
                            />
                            <FormRadioButton
                              id="partial-amount"
                              name="superannuationRollover.transferWholeBalanceIndicator"
                              value="false"
                              label={
                                <span className="d-inline-flex align-items-top">
                                  <span>Partial rollover</span>
                                  <Tippy
                                    arrow={true}
                                    placement="top"
                                    animation="shift-away"
                                    theme="light-border"
                                    content={
                                      <div className="tooltip-popup">
                                        <p>
                                          A partial balance of the member's
                                          contributions will be rolled over to
                                          the receiving fund.
                                        </p>
                                      </div>
                                    }
                                  >
                                    <span className="tooltip-icon">
                                      <InfoCircle />
                                    </span>
                                  </Tippy>
                                </span>
                              }
                            />
                          </FormRadioGroup>

                          {/* <h4>Please specify the amounts below</h4> */}

                          <Checkbox
                            id="remainingBenefitIndicator"
                            checked={
                              values?.superannuationRollover
                                ?.remainingBenefitIndicator
                            }
                            setChecked={() =>
                              setFieldValue(
                                'superannuationRollover.remainingBenefitIndicator',
                                !values?.superannuationRollover
                                  ?.remainingBenefitIndicator,
                              )
                            }
                            label={
                              <span className="d-inline-flex align-items-top">
                                <span>
                                  This member still has remaining benefit in
                                  this SMSF
                                </span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        The member still holds an interest in
                                        the fund within the same account that
                                        the rollover has been paid from.
                                      </p>
                                      <p>
                                        <strong>Note:</strong> This is mandatory
                                        for a Release Authority Statement (RAS),
                                        where amount released is less than
                                        amount requested.
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                          />
                          <hr />
                          <h3>Preservation amounts</h3>
                          <p>
                            <strong>Note:</strong> The sum of the preservation
                            amounts must be equal to the amount of the rollover
                            being paid.
                          </p>
                          <FormGroup
                            name="superannuationRollover.benefitPreservedAmount"
                            className="input--currency"
                            autoComplete="off"
                            onBlur={() => {
                              setFieldTouched(
                                'superannuationRollover.benefitPreservedAmount',
                                true,
                              );
                              setFieldValue(
                                'totalAmount',
                                calculateTotal(values),
                              );
                            }}
                            label={
                              <span className="d-inline-flex align-items-top">
                                <span>Preserved amount</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        Preserved benefits are all contributions
                                        made by or on behalf of a member, and
                                        all earnings since 30 June 1999.
                                      </p>
                                      <p>
                                        This is the preserved amount in relation
                                        to the total amount being transferred.
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                          />

                          <FormGroup
                            name="superannuationRollover.benefitRestrictedAmount"
                            className="input--currency"
                            autoComplete="off"
                            onBlur={() => {
                              setFieldTouched(
                                'superannuationRollover.benefitRestrictedAmount',
                                true,
                              );
                              setFieldValue(
                                'totalAmount',
                                calculateTotal(values),
                              );
                            }}
                            label={
                              <span className="d-inline-flex align-items-top">
                                <span>Restricted non-preserved amount</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        Restricted non-preserved benefits
                                        include any employment-related
                                        contributions you may have made before 1
                                        July 1999, excluding employer
                                        contributions.
                                      </p>
                                      <p>
                                        This is the Restricted non-preserved
                                        amount in relation to the total amount
                                        being transferred.
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                          />

                          <FormGroup
                            name="superannuationRollover.benefitUnrestrictedAmount"
                            className="input--currency"
                            autoComplete="off"
                            onBlur={() => {
                              setFieldTouched(
                                'superannuationRollover.benefitUnrestrictedAmount',
                                true,
                              );
                              setFieldValue(
                                'totalAmount',
                                calculateTotal(values),
                              );
                            }}
                            label={
                              <span className="d-inline-flex align-items-top">
                                <span>Unrestricted non-preserved amount</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        Unrestricted non-preserved benefits
                                        include any benefits that may be paid on
                                        demand by your super fund because you
                                        have already satisfied a condition of
                                        release, such as:
                                      </p>
                                      <ul className="list">
                                        <li>
                                          Being aged over the preservation age
                                          and retiring.
                                        </li>
                                        <li>
                                          Being aged over the preservation age
                                          and starting a
                                          transition-to-retirement income stream
                                          (TRIS)
                                        </li>
                                        <li>
                                          Being aged over 60 and ceasing an
                                          employment arrangement
                                        </li>
                                        <li>
                                          Being aged 65 or over, even if you are
                                          still working.
                                        </li>
                                      </ul>
                                      <p>
                                        This is the Unrestricted non-preserved
                                        amount in relation to the total amount
                                        being transferred.
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                          />

                          <div className="form-group">
                            <Checkbox
                              id="deathBenefitSelected"
                              checked={
                                values?.superannuationRollover
                                  ?.deathBenefitSelected
                              }
                              setChecked={() =>
                                setFieldValue(
                                  'superannuationRollover.deathBenefitSelected',
                                  !values?.superannuationRollover
                                    ?.deathBenefitSelected,
                                )
                              }
                              label="I am rolling over a super death benefit"
                            />
                          </div>
                          {values.superannuationRollover
                            .deathBenefitSelected && (
                            <>
                              <FormGroup
                                name="superannuationRollover.deathBenefitRecipientAgeCategoryCode"
                                as="select"
                                label={
                                  <span className="d-inline-flex align-items-top">
                                    <span>
                                      Death benefit recipient category
                                    </span>
                                    <Tippy
                                      arrow={true}
                                      placement="top"
                                      animation="shift-away"
                                      theme="light-border"
                                      content={
                                        <div className="tooltip-popup">
                                          <p>
                                            The eligible dependant of the
                                            deceased member must be a
                                            <ul className="list">
                                              <li>spouse or former spouse</li>
                                              <li>child*</li>
                                              <li>
                                                trustee of the deceased estatea
                                              </li>
                                            </ul>
                                            *A child: a) is under 18 years old,
                                            b) between 18-25 years old and
                                            financially dependent on the member,
                                            c) has a permanent disability.
                                          </p>
                                        </div>
                                      }
                                    >
                                      <span className="tooltip-icon">
                                        <InfoCircle />
                                      </span>
                                    </Tippy>
                                  </span>
                                }
                              >
                                <option>Please select...</option>
                                {DEATH_BENEFIT_RECIPIENT_AGE_CATEGORY_CODE.map(
                                  ({ value, label }) => (
                                    <option key={value} value={value}>
                                      {label}
                                    </option>
                                  ),
                                )}
                              </FormGroup>
                              <FormGroup
                                name="superannuationRollover.deathBenefitTaxCode"
                                // label="Death benefit tax code"
                                as="select"
                                label={
                                  <span className="d-inline-flex align-items-top">
                                    <span>
                                      Death benefit tax treatment type
                                    </span>
                                    <Tippy
                                      arrow={true}
                                      placement="top"
                                      animation="shift-away"
                                      theme="light-border"
                                      content={
                                        <div className="tooltip-popup">
                                          <p>
                                            This represents the tax treatment
                                            for the superannuation death benefit
                                            rollover. Valid types are:
                                          </p>
                                          <ul className="list">
                                            <li>
                                              The dependant is entitled to a
                                              concessionally taxed income stream
                                              (because the deceased member died
                                              aged 60 years or over, and/or the
                                              dependant is aged 60 years or
                                              over)
                                            </li>
                                            <li>
                                              The dependant is not entitled to a
                                              concessionally taxed income stream
                                              (because the deceased member died
                                              aged under 60, and the dependant
                                              is aged under 60)
                                            </li>
                                          </ul>
                                        </div>
                                      }
                                    >
                                      <span className="tooltip-icon">
                                        <InfoCircle />
                                      </span>
                                    </Tippy>
                                  </span>
                                }
                              >
                                <option>Please select...</option>
                                {DEATH_BENEFIT_TAX_CODE.map(
                                  ({ value, label }) => (
                                    <option key={value} value={value}>
                                      {label}
                                    </option>
                                  ),
                                )}
                              </FormGroup>
                            </>
                          )}
                          <hr />
                          <h3>Tax components</h3>
                          <p>
                            <strong>Note:</strong> The amount of the tax
                            components total must be equal to the amount of the
                            rollover being paid. Make sure you apply the
                            proportioning rule to the tax components if you are
                            not rolling over the member's full interest in your
                            fund.
                          </p>
                          <FormGroup
                            name="incomeTax.taxFreeAmount"
                            className="input--currency"
                            autoComplete="off"
                            label={
                              <span className="d-inline-flex align-items-top">
                                <span>Tax free component</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        The tax-free component consists of the
                                        crystallised and contributions segments
                                        of the rollover benefit.
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                          />
                          <h4>Taxable component</h4>
                          <FormGroup
                            name="incomeTax.taxableTaxedAmount"
                            className="input--currency"
                            autoComplete="off"
                            label={
                              <span className="d-inline-flex align-items-top">
                                <span>Taxed element</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        The taxable component is the total value
                                        of the rollover less the value of the
                                        tax-free component and the KiwiSaver
                                        amount.
                                      </p>
                                      <p>
                                        The tax component helps the receiving
                                        fund work out the amount of income tax
                                        the member will be liable for when they
                                        are paid a benefit from the amounts
                                        being rolled over.
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                          />
                          <FormGroup
                            name="incomeTax.taxableUntaxedAmount"
                            className="input--currency"
                            autoComplete="off"
                            label={
                              <span className="d-inline-flex align-items-top">
                                <span>Untaxed element</span>
                                <Tippy
                                  arrow={true}
                                  placement="top"
                                  animation="shift-away"
                                  theme="light-border"
                                  content={
                                    <div className="tooltip-popup">
                                      <p>
                                        The untaxed element includes amounts
                                        where a fund has not paid any tax on the
                                        contributions or earnings. This usually
                                        occurs because the payment is sourced at
                                        least in part from a scheme that is not
                                        subject to tax.
                                      </p>
                                      <p>
                                        An element untaxed in the fund only
                                        arises in rollovers from certain
                                        unfunded schemes, usually public sector
                                        super funds. For the untaxed plan cap
                                        amount for any given financial year
                                        refer to key superannuation rates and
                                        thresholds on the ATO website
                                        (https://ato.gov.au)
                                      </p>
                                    </div>
                                  }
                                >
                                  <span className="tooltip-icon">
                                    <InfoCircle />
                                  </span>
                                </Tippy>
                              </span>
                            }
                          />
                          <hr />
                          <h3>Other</h3>
                          <Checkbox
                            id="insuranceSelected"
                            checked={
                              values?.superannuationRollover?.insuranceSelected
                            }
                            setChecked={() =>
                              setFieldValue(
                                'superannuationRollover.insuranceSelected',
                                !values?.superannuationRollover
                                  ?.insuranceSelected,
                              )
                            }
                            label="Insurance"
                          />
                          {values.superannuationRollover.insuranceSelected && (
                            <>
                              <FormGroup
                                name="superannuationRollover.insuredDeathCoverAmount"
                                // label="Death"
                                className="input--currency"
                                autoComplete="off"
                                label={
                                  <span className="d-inline-flex align-items-top">
                                    <span>Death cover</span>
                                    <Tippy
                                      arrow={true}
                                      placement="top"
                                      animation="shift-away"
                                      theme="light-border"
                                      content={
                                        <div className="tooltip-popup">
                                          <p>
                                            The total sum insured amount of any
                                            death cover in place on the member
                                            account that will lapse as a result
                                            of the rollover transaction.
                                          </p>
                                          <p>
                                            Only required for full rollovers. If
                                            no death insurance existed or the
                                            rollover will not cause it to be
                                            cancelled at the time the rollover
                                            occurs, report "0".
                                          </p>
                                        </div>
                                      }
                                    >
                                      <span className="tooltip-icon">
                                        <InfoCircle />
                                      </span>
                                    </Tippy>
                                  </span>
                                }
                              />
                              <FormGroup
                                name="superannuationRollover.insuredTotalPermanentDisablementCoverAmount"
                                // label="Permanent"
                                className="input--currency"
                                autoComplete="off"
                                label={
                                  <span className="d-inline-flex align-items-top">
                                    <span>Permanent disablement cover</span>
                                    <Tippy
                                      arrow={true}
                                      placement="top"
                                      animation="shift-away"
                                      theme="light-border"
                                      content={
                                        <div className="tooltip-popup">
                                          <p>
                                            The total insured amount of
                                            permanent disablement (TPD) cover in
                                            place on the member account that
                                            will lapse as a result of the
                                            rollover transaction.
                                          </p>
                                          <p>
                                            Only required for full rollovers. If
                                            no TPD insurance existed or the
                                            rollover will not cause it to be
                                            cancelled at the time the rollover
                                            occurs report "0".
                                          </p>
                                        </div>
                                      }
                                    >
                                      <span className="tooltip-icon">
                                        <InfoCircle />
                                      </span>
                                    </Tippy>
                                  </span>
                                }
                              />
                              <FormGroup
                                name="superannuationRollover.insuredIncomeProtectionMonthlyCoverAmount"
                                // label="Income"
                                className="input--currency"
                                autoComplete="off"
                                label={
                                  <span className="d-inline-flex align-items-top">
                                    <span>Income protection cover</span>
                                    <Tippy
                                      arrow={true}
                                      placement="top"
                                      animation="shift-away"
                                      theme="light-border"
                                      content={
                                        <div className="tooltip-popup">
                                          <p>
                                            The monthly sum insured amount of
                                            any income protection/salary
                                            continuance insurance cover in place
                                            on the member account that will
                                            lapse as a result of the rollover
                                            transaction.
                                          </p>
                                          <p>
                                            Only required for full rollovers. If
                                            no income protection/salary
                                            continuance insurance existed or the
                                            rollover will not cause it to be
                                            cancelled at the time the rollover
                                            occurs report "0".
                                          </p>
                                        </div>
                                      }
                                    >
                                      <span className="tooltip-icon">
                                        <InfoCircle />
                                      </span>
                                    </Tippy>
                                  </span>
                                }
                              />
                            </>
                          )}

                          <Checkbox
                            id="kiwiSaverSelected"
                            checked={
                              values?.superannuationRollover?.kiwiSaverSelected
                            }
                            setChecked={() =>
                              setFieldValue(
                                'superannuationRollover.kiwiSaverSelected',
                                !values?.superannuationRollover
                                  ?.kiwiSaverSelected,
                              )
                            }
                            label="KiwiSaver"
                          />
                          {values?.superannuationRollover
                            ?.kiwiSaverSelected && (
                            <>
                              <FormGroup
                                name="superannuationRollover.kiwiSaverPreservedAmount"
                                // label="Preserved"
                                className="input--currency"
                                autoComplete="off"
                                label={
                                  <span className="d-inline-flex align-items-top">
                                    <span>Preserved amount</span>
                                    <Tippy
                                      arrow={true}
                                      placement="top"
                                      animation="shift-away"
                                      theme="light-border"
                                      content={
                                        <div className="tooltip-popup">
                                          <p>
                                            A New Zealand-sourced amount in a
                                            complying superannuation fund is
                                            treated as preserved benefits in the
                                            same way as other amounts in the
                                            fund would be treated as preserved
                                            benefits.
                                          </p>
                                        </div>
                                      }
                                    >
                                      <span className="tooltip-icon">
                                        <InfoCircle />
                                      </span>
                                    </Tippy>
                                  </span>
                                }
                              />
                              <FormGroup
                                name="superannuationRollover.kiwiSaverTaxFreeAmount"
                                // label="Tax free"
                                className="input--currency"
                                autoComplete="off"
                                label={
                                  <span className="d-inline-flex align-items-top">
                                    <span>Tax free amount</span>
                                    <Tippy
                                      arrow={true}
                                      placement="top"
                                      animation="shift-away"
                                      theme="light-border"
                                      content={
                                        <div className="tooltip-popup">
                                          <p>
                                            The KiwiSaver tax-free amount is the
                                            entire KiwiSaver amount excluding
                                            any part of the contribution the
                                            member has previously shown to be a
                                            returning Australian-sourced amount
                                            – which is generally an amount that
                                            was previously received by a
                                            participating KiwiSaver scheme from
                                            an Australian super provider and was
                                            originally accrued in an Australian
                                            complying super fund.
                                          </p>
                                          <p>
                                            <strong>Note:</strong> A KiwiSaver
                                            amount cannot be rolled over to a
                                            self-managed super fund (SMSF).
                                          </p>
                                        </div>
                                      }
                                    >
                                      <span className="tooltip-icon">
                                        <InfoCircle />
                                      </span>
                                    </Tippy>
                                  </span>
                                }
                              />
                            </>
                          )}
                          <div className="panel__footer">
                            <h3>
                              Total amount
                              <Tippy
                                arrow={true}
                                placement="top"
                                animation="shift-away"
                                theme="light-border"
                                content={
                                  <div className="tooltip-popup">
                                    <p>
                                      The total amount that will be paid through
                                      this payment record.
                                    </p>
                                  </div>
                                }
                              >
                                <span className="tooltip-icon">
                                  <InfoCircle />
                                </span>
                              </Tippy>
                            </h3>

                            <FormGroup
                              name="totalAmount"
                              className="input--currency"
                              disabled
                            />
                          </div>
                        </div>

                        {submissionError && (
                          <ValidationError
                            validation={submissionError.validationResponse}
                            exception={submissionError.exception}
                          />
                        )}

                        <div className="button-group mt-6">
                          <button
                            type="submit"
                            className="button button--primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                          <Link
                            to={`/smsfs/${guid}/rollovers`}
                            className="button button--transparent button--lg"
                          >
                            Cancel
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div className="button-group mt-6">
                        <Link
                          to={`/smsfs/${guid}/rollovers`}
                          className="button button--transparent button--lg"
                        >
                          Cancel
                        </Link>
                      </div>
                    )}
                  </DisabledFields>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default RollOutRequest;
