import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { ReactComponent as ArrowIn } from 'assets/images/icons/icon_arrow-in.svg';
import { ReactComponent as ArrowOut } from 'assets/images/icons/icon_arrow-out.svg';
import { useEffect, useState } from 'react';
import HttpClient from 'utils/HttpClient';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from 'components/Spinner/Spinner';
import Alert from 'components/Alert/Alert';
import { convertToAuFllDateFormat } from 'utils/format';

import { ReactComponent as PlusCircle } from 'bootstrap-icons/icons/plus-circle.svg';
import { ReactComponent as ChevronDown } from 'bootstrap-icons/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'bootstrap-icons/icons/chevron-up.svg';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import AddRolloverErrorModal from './AddRolloverErrorModal';
import FundDetail from './FundDetail';
import { getPageHeader, getType, getTransactionType } from 'utils/labels';
import SpinnerModal from 'components/SpinnerModal/SpinnerModal';

import { RolloverSearchService } from 'services';
import { SideBarContent } from './SideBarContent';
import { MemberDetailsTable } from './MemberDetailsTable';
import { useFundContext } from 'contexts/fundContext';
import {SMSF_STATUS} from "../../constants/smsf";

const InitialError = {
  status: '',
  message: '',
};

const RolloverDetail = () => {
  const history = useHistory();
  const { guid, messageTransactionId } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(InitialError);
  const fund = useFundContext();

  const [showAddRolloverErrorModal, setShowAddRolloverErrorModal] =
    useState(false);
  const handleCloseAddRolloverErrorModal = () =>
    setShowAddRolloverErrorModal(false);
  const handleShowAddRolloverErrorModal = () =>
    setShowAddRolloverErrorModal(true);

  const [showSendErrorPanel, setShowSendErrorPanel] = useState(false);
  const [sendErrorResponseOutcomes, setSendErrorResponseOutcomes] = useState(
    [],
  );
  const [isSendingErrorResponse, setIsSendingErrorResponse] = useState(false);

  const gotToTransaction = async (transactionId) => {
    history.push(`/smsfs/${guid}/rollovers/${transactionId}`);
    history.go(0);
  };

  const isActive = () => {
    return fund?.smsfStatus === SMSF_STATUS.active;
  };

  useEffect(() => {
    let didCancel = false;

    const getResults = async () => {
      setError(InitialError);

      try {
        const res = await RolloverSearchService.searchByTransactionId(
          guid,
          messageTransactionId,
        );

        if (!didCancel) {
          setData(res?.data);
          setError(InitialError);
          setIsLoaded(true);
        }
      } catch (err) {
        if (!didCancel) {
          setIsLoaded(true);
          if (err.response) {
            setData(null);
            setError({
              status: err.response.status.toString(),
              message: err.response.data.message || err.response.data,
            });
          } else if (err.request) {
            alert(err.request);
          } else {
            alert('Error', err.message);
          }
        }
      }
    };

    if (!isLoaded) {
      getResults();
    }
    return () => {
      didCancel = true;
    };
  }, [messageTransactionId, isLoaded]);

  const addSendErrorResponseOutcomes = (errorResponseOutcome) => {
    const updatedSendErrorResponseOutcomes = sendErrorResponseOutcomes.slice(0);
    updatedSendErrorResponseOutcomes.push(errorResponseOutcome);
    setSendErrorResponseOutcomes(updatedSendErrorResponseOutcomes);
  };

  const getTotalRefundAmountForSendErrorResponseOutcomes = () => {
    return sendErrorResponseOutcomes
      .map((errorResponseOutcome) =>
        errorResponseOutcome.refundAmount &&
        errorResponseOutcome.refundAmount !== ''
          ? Number(errorResponseOutcome.refundAmount)
          : 0,
      )
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  };

  const isAddRolloverErrorEnabled = () => {
    return (
      (data?.rolloverType === 'ROLL_IN' &&
        data?.requestAction === 'ROLLOVER_TRANSACTION_REQUEST') ||
      (data?.rolloverType === 'ROLL_OUT' &&
        data?.requestAction === 'INITIATE_ROLLOVER_REQUEST') ||
      (data?.rolloverType === 'RAS_OUT' &&
        data?.requestAction === 'RELEASE_AUTHORITY')
    );
  };

  const getSendErrorResponseUrl = () => {
    let url = '';
    if (
      data?.rolloverType === 'ROLL_IN' &&
      data?.requestAction === 'ROLLOVER_TRANSACTION_REQUEST'
    ) {
      url = '/smsf-hub/roll-in/rollover-transaction/error-outcome-response';
    } else if (
      data?.rolloverType === 'ROLL_OUT' &&
      data?.requestAction === 'INITIATE_ROLLOVER_REQUEST'
    ) {
      url = '/smsf-hub/roll-out/initiation/error-outcome-response';
    } else if (
      data?.rolloverType === 'RAS_OUT' &&
      data?.requestAction === 'RELEASE_AUTHORITY'
    ) {
      url = '/smsf-hub/roll-out/release-authority/error-outcome-response';
    }
    return url;
  };

  const sendErrorResponse = async () => {
    try {
      setIsSendingErrorResponse(true);
      const res = await HttpClient.post(getSendErrorResponseUrl(), {
        errorOutcomes: sendErrorResponseOutcomes.map((errorResponseOutcome) => {
          return {
            contextId: errorResponseOutcome.errorContextId,
            errorCode: errorResponseOutcome.errorReasonCode,
            refundAmount:
              errorResponseOutcome.refundAmount &&
              errorResponseOutcome.refundAmount !== ''
                ? errorResponseOutcome.refundAmount
                : null,
            comments: errorResponseOutcome.comments,
          };
        }),
        messageTransactionId: messageTransactionId,
      });
      setIsSendingErrorResponse(false);
      setSendErrorResponseOutcomes([]);
      setIsLoaded(false);
    } catch (err) {
      setIsSendingErrorResponse(false);
      if (err.response) {
        setData(null);
        setError({
          status: err.response.status.toString(),
          message: err.response.data.message || err.response.data,
        });
      } else if (err.request) {
        alert(err.request);
      } else {
        alert('Error', err.message);
      }
    }
  };

  const deleteSendErrorResponseOutcome = ({ errorResponseOutcomeIndex }) => {
    setSendErrorResponseOutcomes(
      sendErrorResponseOutcomes.splice(errorResponseOutcomeIndex + 1, 1),
    );
  };

  const getTransactionLevelAlertTitle = () => {
    let alertTitle = '';
    if (data?.validationResponse !== null) {
      alertTitle = 'This rollover has been rejected.';
    } else if (data?.totalRefundAmount !== null) {
      if (
        data?.rolloverType === 'ROLL_IN' &&
        data?.requestAction === 'ROLLOVER_TRANSACTION_REQUEST'
      ) {
        alertTitle = 'A refund has been sent for this rollover.';
      } else if (
        data?.rolloverType === 'RAS_OUT' ||
        (data?.rolloverType === 'ROLL_OUT' &&
          data?.requestAction === 'ROLLOVER_TRANSACTION_REQUEST')
      ) {
        alertTitle = 'A refund has been received for this rollover.';
      }
    }
    return alertTitle;
  };

  return (
    <div className="rollovers-detail">
      {isLoaded ? (
        <>
          <div className="banner banner--fixed">
            <Breadcrumbs />
            <div>
              <h1>{getPageHeader(data?.rolloverType, data?.requestAction)}</h1>
              {data?.dateUpdated != null && (
                <h2>
                  <strong>Conversation ID:</strong> {data?.conversationId} |{' '}
                  <strong>Last updated:</strong>{' '}
                  {convertToAuFllDateFormat(data?.dateUpdated)}{' '}
                </h2>
              )}
            </div>
          </div>
          <div className="main__2col">
            <div className="content-area">
              {isLoaded && error.status.length > 0 && (
                <>
                  <div className="error-section">
                    <h2>{error.status}</h2>
                    <p>{error.message}</p>
                  </div>
                  <br />
                </>
              )}
              {(data?.validationResponse !== null ||
                data?.totalRefundAmount !== null) && (
                <div>
                  <Alert
                    variation="alert"
                    title={getTransactionLevelAlertTitle()}
                  >
                    {/* <p>
                  Please <strong>make payment today</strong> to ensure that it
                  is received by the fund within three business days. Make sure
                  to also include the
                  <strong>Payment Reference Number (PRN)</strong> to avoid
                  delays in processing.
                </p> */}
                    {data?.validationResponse?.validations.map(
                      (validation, index) => {
                        return (
                          <div className="error--container" key={index}>
                            <div>
                              <span className="error__tag">Error</span>
                            </div>
                            <div>
                              <p className="error__title">
                                {validation?.message}
                              </p>
                              <p className="error__content">
                                {validation?.details}
                              </p>
                              {validation?.refundAmount && (
                                <p className="error__refund">
                                  <strong>Refund amount:</strong>{' '}
                                  {validation?.refundAmount}
                                  <br />
                                  <strong>Refund PRN:</strong>{' '}
                                  {validation?.refundPaymentReferenceNumber}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      },
                    )}
                    {data?.totalRefundAmount !== null && (
                      <div className="error--refund-total">
                        Total refund amount: {data?.totalRefundAmount}
                        {/*<button className="button button--outline button--xs">*/}
                        {/*  View refund details*/}
                        {/*</button>*/}
                      </div>
                    )}
                  </Alert>
                </div>
              )}
              {data?.rolloverType === 'ROLL_IN' &&
                data?.requestAction === 'ROLLOVER_TRANSACTION_REQUEST' &&
                data?.memberDetailsList?.some(
                  (memberDetails) => memberDetails?.memberLevelResponseEnabled,
                ) && (
                  <Alert
                    variation="info"
                    title="Payment has been made to your bank account"
                  >
                    <p>
                      Check your bank account and click “Confirm payment
                      received” when you have received payment.
                    </p>
                  </Alert>
                )}
              <article className="participating-funds">
                <FundDetail
                  fund={
                    data?.rolloverType === 'ROLL_IN'
                      ? data?.receivingFund
                      : data?.transferringFund
                  }
                  isReceivingFund={data?.rolloverType === 'ROLL_IN'}
                />
                <div className="arrow">
                  <h2>{getType(data?.rolloverType)}</h2>
                  {data?.rolloverType === 'ROLL_IN' ? (
                    <ArrowIn />
                  ) : (
                    <ArrowOut />
                  )}
                  <h3>{getTransactionType(data?.requestAction)}</h3>
                </div>
                <FundDetail
                  fund={
                    data?.rolloverType === 'ROLL_IN'
                      ? data?.transferringFund
                      : data?.receivingFund
                  }
                  isReceivingFund={data?.rolloverType !== 'ROLL_IN'}
                />
              </article>
              <h2>Member details</h2>

              <div className="table-wrapper">
                <MemberDetailsTable
                  data={data}
                  messageTransactionId={messageTransactionId}
                  guid={guid}
                  setIsLoaded={setIsLoaded}
                  setData={setData}
                  setError={setError}
                  gotToTransaction={gotToTransaction}
                  fund={fund}
                  isActive={isActive}
                />
              </div>
              {!isSendingErrorResponse &&
                data?.errorResponseReferenceData?.errorContexts?.length > 0 &&
                isAddRolloverErrorEnabled() && (
                  <>
                    <button
                      className="button button--outline button--sm my-4 button--icon-xs"
                      onClick={() => {
                        setShowSendErrorPanel(!showSendErrorPanel);
                      }}
                      disabled={!isActive()}
                    >
                      Error
                      {isActive() && (
                        <>
                          {showSendErrorPanel ? (
                            <ChevronUp className="button--icon-after__icon" />
                            ) : (
                            <ChevronDown className="button--icon-after__icon" />
                            )}
                        </>
                      )}
                    </button>
                    {showSendErrorPanel && (
                      <div className="well">
                        {sendErrorResponseOutcomes?.length > 0 && (
                          <div className="table-wrapper">
                            <table className="table table--member">
                              <thead>
                                <tr key="sendErrorPanelHeader">
                                  <th>Name</th>
                                  <th>Error description</th>
                                  <th>Refund details</th>
                                  <th>Comments</th>
                                  <th className="col-xs">&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sendErrorResponseOutcomes.map(
                                  (
                                    errorResponseOutcome,
                                    errorResponseOutcomeIndex,
                                  ) => {
                                    return (
                                      <tr
                                        key={`SendErrorResponseOutcome-${errorResponseOutcomeIndex}`}
                                      >
                                        <td>
                                          {
                                            data.errorResponseReferenceData.errorContexts.filter(
                                              (errorContextDetails) =>
                                                errorContextDetails.contextId ===
                                                errorResponseOutcome.errorContextId,
                                            )[0].contextName
                                          }
                                        </td>
                                        <td>
                                          {
                                            data.errorResponseReferenceData.errorCodes.filter(
                                              (errorCodeDetails) =>
                                                errorCodeDetails.code ===
                                                errorResponseOutcome.errorReasonCode,
                                            )[0].shortDescription
                                          }
                                        </td>
                                        <td>
                                          {errorResponseOutcome.refundAmount &&
                                            errorResponseOutcome.refundAmount !==
                                              '' && (
                                              <>
                                                Refund amount:{' '}
                                                {
                                                  errorResponseOutcome.refundAmount
                                                }
                                                <br />
                                                Refund PRN:{' '}
                                                {data?.paymentReferenceNumber}
                                              </>
                                            )}
                                        </td>
                                        <td>{errorResponseOutcome.comments}</td>
                                        <td>
                                          <button
                                            className="button--icon"
                                            onClick={() => {
                                              deleteSendErrorResponseOutcome({
                                                errorResponseOutcomeIndex,
                                              });
                                            }}
                                          >
                                            <Trash />
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="5">
                                    Total refund amount: $
                                    {getTotalRefundAmountForSendErrorResponseOutcomes()}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        )}
                        <div>
                          <button
                            className="button button--link button--icon-before button--sm my-4"
                            onClick={handleShowAddRolloverErrorModal}
                            disabled={!isActive()}
                          >
                            <PlusCircle className="button--icon-before__icon" />{' '}
                            Add reason
                          </button>
                          <AddRolloverErrorModal
                            showModal={showAddRolloverErrorModal}
                            handleCloseModal={handleCloseAddRolloverErrorModal}
                            errorResponseReferenceData={
                              data?.errorResponseReferenceData
                            }
                            addSendErrorResponseOutcomes={
                              addSendErrorResponseOutcomes
                            }
                          />
                        </div>
                        <button
                          className="button button--outline button--sm my-4"
                          onClick={sendErrorResponse}
                          disabled={
                            !sendErrorResponseOutcomes ||
                            sendErrorResponseOutcomes.length === 0 ||
                            !isActive()
                          }
                        >
                          Reject and send error
                        </button>
                      </div>
                    )}
                  </>
                )}
            </div>
            <div className="content-sidebar">
              <SideBarContent data={data} gotToTransaction={gotToTransaction} />
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
      <SpinnerModal isOpen={isSendingErrorResponse} />
    </div>
  );
};

export default RolloverDetail;
