import { useField } from 'formik';
import { useCallback, useState, useRef, useEffect } from 'react';
import Geosuggest from 'react-geosuggest';
import Checkbox from '../Checkbox/Checkbox';
import FormGroup from '../../components/FormGroup/FormGroup';
import { FormSelect } from '../FormSelect/FormSelect';
import {
  AUSTRALIAN_STATES_AND_TERRITORIES,
  INITIAL_ADDRESS_VALUE,
} from '../../utils/configs';
import AddressType from 'components/AddressType/AddressType';

const AddressFormGroup = ({
  name,
  label,
  initialValue = '',
  addressType,
  isSubmit = false,
  ...props
}) => {
  const findAddressComponentByType = (components, type) => {
    return components.find((component) => component.types[0] === type);
  };

  const [manualAddressInput, setManualAddressInput] = useState(false);

  const [validAddressInput, setValidAddressInput] = useState(true);

  const geosuggestEl = useRef(null);

  const [, meta, helpers] = useField(name);

  const resetAddress = useCallback(() => {
    let resetAddress = INITIAL_ADDRESS_VALUE;
    if (addressType?.enable) {
      resetAddress = { ...resetAddress, addressType: addressType.initialValue };
    }
    helpers.setValue(resetAddress);

    geosuggestEl.current.clear();
  }, [helpers]);

  useEffect(() => {
    if (isSubmit) {
      if (!geosuggestEl.current.value) {
        setValidAddressInput(false);
      }
    }
  }, [isSubmit]);

  return (
    <div className="form-group">
      {addressType?.enable ? <AddressType /> : <label>{label}</label>}
      <Geosuggest
        disabled={manualAddressInput}
        ref={geosuggestEl}
        name={name}
        placeholder="Please enter the full address"
        initialValue={initialValue}
        country="au"
        types={['geocode']}
        minLength="3"
        onBlur={() => helpers.setTouched(true)}
        onSuggestNoResults={() => {
          setValidAddressInput(false);
        }}
        onSuggestSelect={(suggest) => {
          console.log('suggest -', suggest);
          if (suggest) {
            setValidAddressInput(true);
            const {
              label,
              gmaps: { address_components },
            } = suggest;

            helpers.setValue({
              line1: label.split(',')[0],
              suburb: findAddressComponentByType(address_components, 'locality')
                .long_name,
              state: findAddressComponentByType(
                address_components,
                'administrative_area_level_1',
              ).short_name,
              postCode: findAddressComponentByType(
                address_components,
                'postal_code',
              ).short_name,
              countryCode: findAddressComponentByType(
                address_components,
                'country',
              ).short_name.toLowerCase(),
              addressType: addressType?.initialValue,
            });
          }
        }}
        {...props}
      />
      {!isSubmit &&
        meta.touched &&
        !manualAddressInput &&
        !validAddressInput && <div className="error">Address is required</div>}
      <div className="my-4">
        <Checkbox
          id="manual-input"
          name="manualAddressInput"
          checked={manualAddressInput}
          setChecked={(checked) => {
            setManualAddressInput(checked);
            resetAddress();
          }}
          label="I can't find my address"
        />
        {manualAddressInput && (
          <div className="my-2">
            <p className="mt-6">
              <strong>Enter your address in the fields below</strong>
            </p>
            <FormGroup name="address.line1" label="Address" />
            <FormGroup name="address.suburb" label="Suburb" />
            <div className="d-flex gap-8">
              <div className="w-50">
                <FormGroup name="address.state" label="State / Territory">
                  {({ form, meta, name, value, ...props }) => (
                    <FormSelect
                      id="state"
                      options={AUSTRALIAN_STATES_AND_TERRITORIES}
                      {...props}
                      name={name}
                      value={
                        AUSTRALIAN_STATES_AND_TERRITORIES.find(
                          (state) => state.value === value,
                        ) || ''
                      }
                      onChange={(option) =>
                        form.setFieldValue(name, option.value)
                      }
                      onBlur={() => {
                        form.setFieldTouched(name);
                      }}
                    />
                  )}
                </FormGroup>
              </div>
              <div className="w-50">
                <FormGroup name="address.postCode" label="Postcode" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressFormGroup;
