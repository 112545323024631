import Modal from 'react-modal';
import { ReactComponent as XLg } from 'bootstrap-icons/icons/x-lg.svg';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { validateDateFilter } from 'utils/customValidation';
import { TextField } from 'components/TextField/TextField';
import FormGroup from 'components/FormGroup/FormGroup';
import { FormSelect } from 'components/FormSelect/FormSelect';
import { ReportService } from 'services/ReportService/ReportService';
import { useEffect, useState } from 'react';
import { ErrorCodeMessage } from 'components/ErrorCodeMessage/ErrorCodeMessage';
import {
  CONTRIBUTION_CSV_SUMMARY_REPORT_KEY,
  MEMBER_CONTRIBUTION_REPORT_KEY,
  MEMBER_FILTER,
  ReportDetails,
  SMSF_CONTRIBUTION_REPORT_KEY,
} from '../../constants/reports';
import { convertToISO } from '../../utils/date.utils';
import { MemberDetails } from 'services/ReportService/types/ReportService.types';
import { sortBy, uniqBy } from 'lodash';
interface DateRangeFilterDialogProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  smsfGuid: string;
  reportDetails: ReportDetails;
}

Yup.addMethod(Yup.mixed, 'validateDateFilter', validateDateFilter);

export const ReportFilterModal = ({
  isOpen,
  handleCloseModal,
  smsfGuid,
  reportDetails,
}: DateRangeFilterDialogProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [memberList, setMemberList] = useState([{ value: '', label: '' }]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedMemberId, setSelectedMemberId] = useState({
    memberId: '',
    fullName: 'Please select ...',
  });

  const initValues = {
    fromDate: '',
    toDate: '',
    memberId: '',
    filters: reportDetails.filters,
  };

  const resetSelectValues = () => {
    setSelectedMemberId({
      memberId: '',
      fullName: 'Please select ...',
    });
    setErrorMessage('');
  };

  const FilterSchema = Yup.object().shape({
    // @ts-ignore
    fromDate: Yup.mixed().validateDateFilter(),
    // @ts-ignore
    toDate: Yup.mixed().validateDateFilter(),
    memberId: Yup.string().when('filters', {
      // @ts-ignore
      is: (value) => value.includes(MEMBER_FILTER),
      then: Yup.string().required('Please select a member.'),
      otherwise: Yup.string(),
    }),
  });

  const handleSubmit = async (values: any) => {
    const startDate = convertToISO(values.fromDate);
    const endDate = convertToISO(values.toDate);
    let downloadFn;

    if (reportDetails.key === CONTRIBUTION_CSV_SUMMARY_REPORT_KEY) {
      downloadFn = ReportService.downloadContributionCSVSummary;
    } else if (reportDetails.key === MEMBER_CONTRIBUTION_REPORT_KEY) {
      downloadFn = ReportService.downloadMemberContributionSummaryReport;
    } else if (reportDetails.key === SMSF_CONTRIBUTION_REPORT_KEY) {
      downloadFn = ReportService.downloadSMSFContributionSummaryReport;
    } else {
      setErrorMessage(
        'unexpected error!,please contact support if error still persist!',
      );
      setTimeout(() => handleCloseModal(), 3000);
    }

    setIsDownloading(true);
    try {
      // @ts-ignore
      const response = await downloadFn({
        smsfGuid: smsfGuid,
        memberId: selectedMemberId.memberId,
        startDate: startDate,
        endDate: endDate,
      });
      if (response) {
        handleCloseModal();
      } else {
        setErrorMessage(
          'There are no contributions available to download for the selected date range. Try adjusting the filter to find what you are looking for.',
        );
      }
    } catch (e) {
      console.log('error downloading file - ', e);
    }
    setIsDownloading(false);
  };

  const mergeMemberList = (data: MemberDetails[]) => {
    return sortBy(uniqBy(data, 'memberId'), 'fullName');
  };

  useEffect(() => {
    if (reportDetails.filters.includes(MEMBER_FILTER)) {
      (async () => {
        const { data } = await ReportService.retrieveMemberList(smsfGuid);
        const mergedMemberList = mergeMemberList(data ?? []);
        setMemberList(
          mergedMemberList.map((member) => ({
            value: member.memberId,
            label: member.fullName,
          })),
        );
      })();
    }
  }, [reportDetails.filters]);

  useEffect(() => {
    if (!isOpen) {
      resetSelectValues();
    }
  }, [isOpen]);

  const customStyles = {
    menuList: (provided: any, state: any) => ({
      ...provided,
      maxHeight: '200px', // Set the maximum height as needed
      overflowY: 'auto', // Add vertical scroll if content exceeds the maximum height
    }),
  };

  return (
    <Modal
      overlayClassName="modal-overlay"
      isOpen={isOpen}
      contentLabel="Loading"
      className="modal modal--lg"
      //@ts-ignore
      appElement={document.getElementById('root')}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
    >
      <>
        <div className="modal__header_narrow">
          <div className="modal__heading h2" style={{ paddingTop: '1.3rem' }}>
            {reportDetails.header}
          </div>
          <button
            type="button"
            className="d-inline-flex button--transparent button--close"
            aria-label="Close"
            onClick={handleCloseModal}
          >
            <XLg style={{ transform: 'scale(1.5)' }} />
          </button>
        </div>
        <Formik
          initialValues={initValues}
          validationSchema={FilterSchema}
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
        >
          {({ values, setFieldValue, resetForm }) => (
            <Form>
              <div className="modal__body">
                {reportDetails.filters.includes(MEMBER_FILTER) && (
                  <>
                    {' '}
                    <FormGroup name="memberId" label="">
                      {/* @ts-ignore */}
                      {({ form, meta, name, value, ...props }) => (
                        <>
                          <h4>Member</h4>
                          <FormSelect
                            id="member"
                            options={memberList}
                            name={name}
                            //@ts-ignore
                            onChange={(options) => {
                              form.setFieldValue(name, options.value);
                              setSelectedMemberId({
                                memberId: options.value,
                                fullName: options.label,
                              });
                              setErrorMessage('');
                            }}
                            className={`${
                              meta.touched && meta.error && 'is-invalid'
                            }`}
                            value={{
                              id: selectedMemberId.memberId,
                              label: selectedMemberId.fullName,
                            }}
                            styles={customStyles}
                          />
                        </>
                      )}
                    </FormGroup>
                  </>
                )}
                <h4>Payment date range</h4>

                <div className="form-fields-inline__date-range">
                  <div className="form-group" style={{ fontSize: '1.2rem' }}>
                    <TextField
                      label="From date:"
                      name="fromDate"
                      type="date"
                      aria-label="fromDate"
                      icon={null}
                      onSelect={() => setErrorMessage('')}
                    />
                  </div>
                  <div className="form-group" style={{ fontSize: '1.2rem' }}>
                    <TextField
                      label="To date:"
                      name="toDate"
                      type="date"
                      aria-label="toDate"
                      icon={null}
                      onSelect={() => setErrorMessage('')}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <ErrorCodeMessage
                    invalidCode={errorMessage}
                    errClassName={'error'}
                  />
                </div>
              </div>
              <div className="modal__footer button-group">
                <button
                  type="submit"
                  className="button button--primary"
                  disabled={isDownloading}
                >
                  {isDownloading
                    ? `Downloading...`
                    : `Download ${reportDetails.fileType}`}
                </button>
                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    resetSelectValues();
                    resetForm({
                      values: {
                        ...values,
                        fromDate: '',
                        toDate: '',
                        memberId: '',
                      },
                    });
                  }}
                >
                  Reset Filter
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};
