import { ErrorMessage, useField } from 'formik';

export const TextField = ({
  label,
  icon,
  className = '',
  isInvalid = false,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);

  const handleChange = (e) => {
    field.onChange(e); // Call Formik's onChange to update the form values
    if (onChange) {
      // Call the custom onChange if provided
      onChange(e.target.value);
    }
  };

  return (
    <div className="textfield">
      <label htmlFor={field.name}>{label}</label>
      <input
        id={field.name}
        className={`form-control shadow-none ${
          ((meta.touched && meta.error) || isInvalid) && 'is-invalid'
        }`}
        {...field}
        {...props}
        autoComplete="off"
        onChange={handleChange}
      />
      {icon && <div className={`eye ${className}`}>{icon}</div>}
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};
